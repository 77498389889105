<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Orders')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Print shipping labels') + `${items.length > 0 ? ` (${items.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="items.length <= 0"
            @click="handlePrintShippingLabels"
        />


        <q-btn
            v-if="this.appOptions.versionType !== 'simple'"
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Add to Purchase order') + `${items.length > 0 ? ` (${items.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="items.length <= 0"
            @click="handleAddToWarehouse"
        />

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Download excel')"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            @click="downloadExcel"
        />

        <q-btn
            color="positive"
            text-color="white"
            :label="$t('Import')"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="!hasBaseAccess"
            @click="openImportOrders"
        />

        <q-btn-dropdown
            color="light-blue-9"
            size="sm"
            no-caps
            unelevated
            :label="$t('Add order')"
            :disable="!hasBaseAccess"
        >
          <q-list>
            <q-item clickable v-close-popup v-for="type in appOptions.orderTypes" :key="type.type"
                    @click="openCreateOrder(type.type)">
              <q-item-section>
                <q-item-label>
                  {{ $t(type.label) }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilter"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            schema: externalSchema,
            values: {
              states: orderStatuses,
              types: types,
              instanceOf: instanceOf
            }
          }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section class="row q-pa-none">
        <status-filter
            class="hide-on-mobile"
            :outside-selected-status="selectedStatusFilter"
            :statuses="orderStatuses"
            @on-change="onStatusFilter"
        />

        <div class="col">
          <q-table
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="orders"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="ordersLoading"
              :filter="filter"
              selection="multiple"
              v-model:selected="items"
              :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:header-selection="props">
              <q-checkbox
                  size="xl"
                  :model-value="items.length > 0"
                  color="amber-6"
                  @click="handleSelectAll(props)"
              />
            </template>

            <template
                v-slot:body="props"
                class="clickable"
            >
              <order-table-row
                  :data="props"
                  :items="items"
                  :row="props.row"
                  :column="props.column"
                  @check="handleCheck"
                  @dbclick="openOrder(props.row)"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>

    <orders-import ref="ordersImport"/>

    <acceptance-items-modal ref="acceptanceModal"/>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import StatusFilter from '../../components/filters/StatusFilter'
import OrderTableRow from './../../components/order-table-row/OrderTableRow'
import Search from '../../components/search/Search'
import OrdersImport from '../../components/imports/OrdersImportModal'

// Helpers
import { buildQuery } from './../../utils/query-utils'

// Configs
import orderStatusMatrix from './../../config/OrderMatrix'
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'
import _ from 'lodash'
import AcceptanceItemsModal from '@/apps/app/components/modals/AcceptanceItemsModal.vue'

export default {
  name: 'Orders',
  components: {
    AcceptanceItemsModal,
    FiltersCollapse,
    StatusFilter,
    OrderTableRow,
    Search,
    OrdersImport
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isOpenFilter: false,
      orderStatuses: orderStatusMatrix,
      items: [],
      activatedFields: [
        'id',
        'extId',
        'clientId',
        'profile',
        'phone',
        'created.from',
        'created.to',
        'deliveryService',
        'paymentState',
        'state',
        'shop',
        'source',
        'deliveryRequest',
        'shipmentDate.from',
        'shipmentDate.to',
        'type',
        'orderClass'
      ],
      helper: null,
      drStates: this.$utils.createStatuses(null, deliveryRequestStatusMatrix),
      isCargoPlanet: false,
      externalFilter: {
        state: null,
        orderClass: null
      },
      extAlias: [{ field: 'state', name: 'dr' }],
      instanceOf: [
        { code: 'Orderadmin\\Products\\Entity\\Order', name: 'Retail' },
        { code: 'Orderadmin\\Products\\Entity\\Order\\BundleOrder', name: 'Return' },
        { code: 'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder', name: 'Wholesale' },
        { code: 'Orderadmin\\Products\\Entity\\Order\\ReturnOrder', name: 'Bundle' },
      ],
      prefix: ''
    }
  },
  computed: {
    ...mapGetters([
      'ordersLoading',
      'orders',
      'lockedOrders',
      'ordersItemsNumber',
      'ordersPage',
      'appOptions',
      'hasBaseAccess'
    ]),
    types () {
      return [
        { id: 'retail', code: 'Retail' },
        { id: 'return', code: 'Return' },
        { id: 'wholesaleDraft', code: 'Wholesale Draft' },
        { id: 'wholesale', code: 'Wholesale' },
        { id: 'bundle', code: 'Bundle' },
      ]
    },
    externalSchema () {
      let name = []

      this.instanceOf.forEach(e => {
        if (e.code === this.externalFilter.orderClass) {
          name = e.name
        }
      })
      return [
        {
          type: 'select',
          label: this.$t('Shipping status'),
          wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
          value: this.externalFilter.state,
          field: 'state',
          hasResetBtn: true,
          options: this.drStates,
          customLabel: (row) => {
            if (row && typeof row === 'object') {
              return row.name
            }

            return row
          },
          onChange: (state) => {
            this.externalFilter.state = state && state.code
          }
        },
        {
          type: 'select',
          label: this.$t('Order Type'),
          wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
          value: name,
          field: 'orderClass',
          hasResetBtn: true,
          options: this.instanceOf,
          customLabel: (row) => {
            if (row && typeof row === 'object') {
              return row.name
            }

            return row
          },
          onChange: (state) => {
            this.externalFilter.orderClass = state && state.code
          }
        },
      ]
    },
    ifRecipientEnabled () {
      return this.appOptions.orders?.enableRecipient
    },
    columns () {
      const customerClass = !this.hasBaseAccess
          ? 'd-none'
          : ''

      let columns = [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        }
      ]

      columns.push({
        label: this.$t('Details'),
        name: 'details',
        align: 'left',
        sortable: true
      })

      columns.push({
        label: this.$t('Shipping'),
        name: 'shipping',
        align: 'left'
      })

      return columns
    },
    statuses () {
      return orderStatusMatrix.reduce((acc, group) => {
        return [...acc, ...group.buttons]
      }, [])
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state' && !filter.alias
      })

      if (status && status.values) {
        return status.values
      }

      return status
          ? [status.value]
          : []
    },
    statusFilterClass () {
      const basicStyles = 'btn btn-sm mr-1 d-lg-none '
      return (this.serverParams.filter || []).find(filter => filter.field === 'state')
          ? basicStyles + 'btn-success'
          : basicStyles + 'btn-white'
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered:') + this.ordersItemsNumber
          : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()

    // this.addHelper({ message: this.$t('You can create products from here!'), link: '/products', type: 'link' })
    //   .then(helper => {
    //     this.helper = helper
    //   })

    if (this.appOptions.versionType !== 'simple') {
      this.prefix = '/outbound'
    }
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }
  },
  methods: {
    ...mapActions([
      'loadOrders',
      'addHelper',
      'printLabelByRequestByID',
      'printLabelByRequestBySize',
      'getLabels'
    ]),
    ...mapMutations([
      'removeHelper',
      'addWarningNotification',
      'addErrorNotification'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    downloadExcel () {
      return this.$service.order.downloadAll(this.serverParams)
    },
    openImportOrders () {
      this.$refs.ordersImport.open()
    },
    loadDefaultItems () {
      this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadOrders(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
            if (totalItems <= 0) {
              this.addWarningNotification('No orders found')
            }
          })
    },
    resetStatusFilter () {
      const filter = (this.serverParams.filter || []).filter(value => value.field !== 'state')

      this.updateParams({ filter })
      this.loadOrders(this.serverParams)
    },
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      this.loadOrders(query)
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    getFilter (model, alias) {
      return Object.entries(model).reduce((acc, [key, value]) => {
        if (key === 'isNull') {
          return acc
        }

        const a = alias.find(({ field }) => field === key) || {}
        const filter = this.$utils.filter.create(key, value, a.name)

        if (a.parentAlias) {
          filter[0].parentAlias = a.parentAlias
        }

        return [
          ...acc,
          ...filter
        ]
      }, [])
    },
    handleFiltersSubmit (filter) {
      let updatedFilter = [
        ...filter
      ]
      if (this.externalFilter.orderClass) {
        updatedFilter.push({
          type: 'instanceof',
          alias: 'o',
          class: this.externalFilter.orderClass
        })
      }

      if (this.externalFilter.state) {
        updatedFilter.push({
          type: 'like',
          field: 'cache::text',
          value: `%${this.externalFilter.state}%`
        })
      }

      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter: updatedFilter, page: 1 } })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    openCreateOrder (type) {
      this.$router.push(`${this.prefix}/orders/entity/${type}`)
    },
    openOrder (order) {
      this.$router.push(`${this.prefix}/orders/entity/${order.type}/${order.id}`)
    },
    async handlePrintShippingLabels () {
      for (const selectedItem of this.items) {
        if (selectedItem._embedded && selectedItem._embedded.deliveryRequest)
          await this.printLabelByRequestByID(selectedItem._embedded.deliveryRequest)
      }
    },
    printLabelByRequestByID (deliveryRequest) {
      return new Promise((resolve) => {
        const request = deliveryRequest

        this.$service.deliveryServicePreprocessingTask.getByDeliveryRequest(request.id)
            .then(result => {
              if (!result) {
                return this.printLabelByRequestBySize({ pageSize: 'label' })
              }

              return this.getLabels({ id: result.id })
            })
            .catch(error => {
              this.addErrorNotification(error.message)
            })
            .finally(() => {
              resolve()
            })
      })
    },
    handleAddToWarehouse () {
      const query = {
        per_page: 250,
        filter: []
      }
      const orderIDs = this.items.map(e => e.id)
      query.filter = [{ alias: 'op', field: 'order', type: 'in', values: orderIDs }]

      this.isLoading = true
      return this.$service.order.getProducts(query)
          .then(({ items, page, totalItems }) => {
            let result = items.filter(e => e.productOfferRaw?.type !== 'bundle')
            result = result.map(x => {
              return {
                ...x._embedded.productOffer,
                ...x,
                id: x._embedded.productOffer.id,
                raw: x
              }
            })
            const updatedItems = result.map(item => {
              return {
                ...item,
                sku: Array.isArray(item.barcodes) && item.barcodes.length >= 1
                    ? item.barcodes[0]
                    : '',
                state: 'new',
                dimensions: item.dimensions && typeof item.dimensions === 'object'
                    ? item.dimensions
                    : { x: 0, y: 0, z: 0 }
              }
            })

            this.$refs.acceptanceModal.show(updatedItems)
          })

    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleCheck (item) {
      let hasItem = false
      if (this.items.length > 0) {
        if (this.items[0]?._embedded?.shop.id && item?._embedded?.shop.id) {
          if (this.items[0]?._embedded?.shop.id !== item?._embedded?.shop.id) {
            this.addWarningNotification('You can select orders from one shop')
            return false
          }
        }
      }

      this.items = this.items.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }
    },
  }
}
</script>
